export const activityHeaderHome = [
  { key: "element", label: "" },
  { key: "title", label: "Title" },
  { key: "responsible", label: "Responsible" },
  { key: "members", label: "Members" },
  { key: "price", label: "Price" },
  { key: "production_time", label: "Time" },
  { key: "status", label: "Progress" },
  { key: "actions", label: "Actions" }
];

export const activityHeader = [
  { key: "title", label: "Title" },
  { key: "responsible", label: "Responsible" },
  { key: "members", label: "Members" },
  { key: "status", label: "Status" },
  { key: "price", label: "Price" },
  { key: "production_time", label: "Timer" },
  { key: "progress", label: "Progress" },
  { key: "actions", label: "Actions" },
];

export const taskHeader = [
  { key: "id", label: "ID" },
  { key: "title", label: "Title" },
  { key: "description", label: "Description" },
  { key: "projects", label: "Projects" },
  { key: "actions", label: "Actions" },
];

export const membersHeaders = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "email", label: "Email" },
  { key: "created_at", label: "Created Date" },
];

export const activityTasksHeaders = [
  { key: "title", label: "Title" },
  { key: "status", label: "Status" },
  { key: 'owner', label: 'Responsibles' },
  { key: "price", label: "Price" },
  { key: "duration", label: "Duration (Hours)" },
  { key: "timer", label: "Timer" },
  { key: "actions", label: "Actions" },
];

export const tasksHeaders = [
  { key: "id", label: "ID" },
  { key: "title", label: "Title" },
  { key: "status", label: "Status" },
  { key: "price", label: "Price" },
  { key: "actions", label: "" },
];

export const projectsHeaders = [
  { key: "id", label: "ID" },
  { key: "title", label: "Title" },
  { key: "activities", label: "Activities" },
  // { key: "tasks", label: "Tasks" },
  { key: "status", label: "Status" },
  { key: "members", label: "Members" },
  { key: "actions", label: "Actions" },
];

export const organizationsHeaders = [
  { key: "id", label: "ID" },
  { key: "title", label: "Title" },
  { key: "idOwner", label: "Owner" },
  { key: "idMembers", label: "Members" },
  { key: "actions", label: "Actions" },
];

export const activitiesHeaders = [
  { key: "id", label: "ID" },
  { key: "title", label: "Title" },
  { key: "status", label: "Status" },
  { key: "price", label: "Price" },
  { key: "actions", label: "Actions" },
];

export const departamentsHeaders = [
  { key: "id", label: "ID" },
  { key: "title", label: "Title" },
  { key: "owner", label: "Owner" },
  { key: "members", label: "Members" },
  { key: "actions", label: "Actions" },
];

export const homeTaskHeader = [
  { key: "title", label: "Title" },
  { key: "owner", label: "Owner" },
  { key: "price", label: "Price" },
  { key: "status", label: "Status" },
  { key: "timer", label: 'Timer' },
  { key: "duration", label: "Duration (Hours)" },
  { key: "actions", label: "Actions" },
];

export const logHeader = [
  { key: "user", label: "usuário" },
  { key: "date", label: "horário" },
  { key: "type", label: "tipo" },
  { key: "action", label: "Action" }
]

export const taskLogsHeader = [
  { key: "user.name", label: "usuário" },
  { key: "date", label: "horário" },
  { key: "action", label: "Action" }
]