export function variantStatusTask(status) {
  const statusMap = {
    to_do: 'primary',
    on_going: 'info',
    pending: 'warning',
    to_review: 'warning',
    to_correct: 'info',
    waiting_for_aproval: 'warning',
    approved: 'success',
    rejected: 'danger',
    canceled: 'danger',
    to_upload: 'info'
  }

  return statusMap[status] || 'primary'
}

export function textStatusTask(status) {
  const statusMap = {
    to_do: 'TO DO',
    on_going: 'ON GOING',
    pending: 'PENDING',
    to_review: 'TO REVIEW',
    to_correct: 'TO CORRECT',
    waiting_for_aproval: 'WATING FOR APROVAL',
    approved: 'APPROVED',
    rejected: 'REJECTED',
    canceled: 'CANCELED',
    to_upload: 'TO UPLOAD'
  }

  return statusMap[status] || '-'
}

function categoryStatus(categ) {
  if (categ === "pending") return "primary"
  if (categ === "review") return "warning"
  if (categ === "canceled") return "danger"
  if (categ === "in_process") return "info"
  if (categ === "done") return "success"
  return "-"
}

function resolveStatusText(categ) {
  if (categ === "pending") return "PENDING"
  if (categ === "review") return "REVIEW"
  if (categ === "canceled") return "CANCELED"
  if (categ === "video") return "VIDEO"
  if (categ === "done") return "DONE"
  if (categ === "in_process") return "IN PROCESS"
  return "-"
}

let statusOptions = ['pending', 'done', 'canceled', 'review', 'in_process']
let activityStatusOptions = [
  {
    label: 'ACTIVATED',
    key: 'activated',
  },
  {
    label: 'COMPLETED',
    key: 'completed'
  },
  {
    label: 'CANCELLED',
    key: 'cancelled'
  },
  {
    label: 'PENDING',
    key: 'pending'
  }
]
let projectStatusOptions = [
  {
    label: 'ACTIVATED',
    key: 'activated',
  },
  {
    label: 'FINISHED',
    key: 'finished'
  }
]

let taskStatusOptions = {
  "all":
    [
      {
        'label': 'TO DO',
        'key': 'to_do'
      },
      {
        'label': 'ON GOING',
        'key': 'on_going'
      },
      {
        'label': 'PENDING',
        'key': 'pending'
      },
      {
        'label': 'TO REVIEW',
        'key': 'to_review'
      },
      {
        'label': 'TO CORRECT',
        'key': 'to_correct'
      },
      {
        'label': 'WAITING FOR APROVAL',
        'key': 'waiting_for_aproval'
      },
      {
        'label': 'APPROVED',
        'key': 'approved'
      },
      {
        'label': 'REJECTED',
        'key': 'rejected'
      },
      {
        'label': 'CANCELLED',
        'key': 'cancelled'
      },
      {
        'label': 'TO UPLOAD',
        'key': 'to_upload'
      }
    ],
  "employee":
    [
      {
        'label': 'PENDING',
        'key': 'pending'
      },
      {
        'label': 'TO REVIEW',
        'key': 'to_review'
      },
      {
        'label': 'TO CORRECT',
        'key': 'to_correct'
      },
      {
        'label': 'WAITING FOR APROVAL',
        'key': 'waiting_for_aproval'
      }
    ]
}

export { categoryStatus, resolveStatusText, statusOptions, taskStatusOptions, projectStatusOptions, activityStatusOptions }