<template>
  <b-card>
    <b-row>
      <b-col cols="12" lg="6" md="6" sm="12" xs="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0" />
      <b-col cols="12" lg="12" md="12" sm="12" xs="12">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <b-col class="d-flex" cols="12" lg="4" md="2" sm="2" xs="4">
            <v-select :options="options" label="pagination" v-model="perPage" />
          </b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-col cols="12" lg="" md="12" sm="4" xs="12" style="width: 40vh;">
              <b-form-input class="" v-model="search" type="search" placeholder="Search" />
            </b-col>
            <b-button variant="outline-primary" @click="$router.push('/organizations/create')">
              + Organization
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-table id="organizationTable" responsive show-empty :items="filterOrganization" :key="filterOrganization.id"
      :fields="fields" select-mode="single" ref="selectableTable" :per-page="perPage" :current-page="currentPage">
      <!-- Created date -->
      <template #cell(created_at)="data">
        {{ convertCreatedDate(data.item.created_at) }}
      </template>

      <!-- Owner -->
      <template #cell(idOwner)="data">
        <b-avatar :id="'avatar-' + data.item.id" class="mr-1" size="40" variant="info" :src="data.item.owner.avatar">
          <b-img v-if="data.item.owner.avatar != null && data.item.owner.avatar != ''" fluid
            :src="data.item.owner.avatar"></b-img>
          <div v-else>
            {{ data.item.owner.name.substr(0, 1).toUpperCase() }}
          </div>
        </b-avatar>
        <b-tooltip :target="`avatar-${data.item.id}`">
          {{ data.item.owner.name }}
        </b-tooltip>
      </template>

      <!-- Members -->
      <template #cell(idMembers)="data">
        <div>
          <b-dropdown no-caret boundary=".table-container" variant="outline"> <template #button-content>
              <b-row v-if="data.item.members.length === 1">
                <b-avatar v-for="item in data.item.members" :key="item.id" variant="primary" size="40" :src="item.avatar">
                  <b-img v-if="item.avatar != null && item.avatar != ''" fluid :src="item.avatar" />
                  <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
                </b-avatar>
              </b-row>
              <b-row v-else-if="data.item.members.length <= 3">
                <b-avatar-group size="40" variant="primary">
                  <b-avatar v-for="item in data.item.members" :key="item.id" :src="item.avatar">
                    <b-img v-if="item.avatar != null && item.avatar != ''" fluid :src="item.avatar" />
                    <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
                  </b-avatar>
                </b-avatar-group>
              </b-row>
              <b-row v-else>
                <b-avatar-group size="40" variant="primary">
                  <b-avatar v-for="i in 3" :key="i">
                    <b-img v-if="data.item.members[i].avatar != null && item.avatar != ''" fluid
                      :src="data.item.members[i].avatar"></b-img>
                    <div v-else>
                      {{ data.item.members[i].name.substr(0, 1).toUpperCase() }}
                    </div>
                  </b-avatar>
                  <b-avatar :text="`+${data.item.members.length - 3}`">
                  </b-avatar>
                </b-avatar-group>
              </b-row>
            </template>
            <div style="max-height: 170px; overflow-y: auto;">
              <b-dropdown-item v-for="item in data.item.members" :key="item.id">
                <b-avatar :src="item.avatar" variant="primary" size="30" class="mr-1">
                  <b-img v-if="item.avatar !== null && item.avatar !== ''" fluid :src="item.avatar" />
                  <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
                </b-avatar>
                {{ item.name }}
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>
      </template>

      <!-- Actions -->
      <template #cell(actions)="row">
        <b-row>
          <b-col cols="2">
            <b-button class="p-0" v-b-tooltip.hover title="Edit" variant="flat" size="sm" @click="clickEdit(row)">
              <feather-icon icon="EditIcon" />
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button class="p-0" v-b-tooltip.hover title="Delete" variant="flat" size="sm" @click="clickModal(row)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-table>

    <!-- Pagination -->
    <b-row align-h="end">
      <b-col md="12" class="d-flex align-items-center justify-content-end">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
          aria-controls="organizationTable"></b-pagination>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCardTitle,
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BIconTrash,
  BModal,
  BIconPen,
  BIconPlusCircle,
  BContainer,
  BFormInput,
  BAvatar,
  BPagination,
  BImg,
  BIconEye,
  BAvatarGroup,
  BDropdown,
  BDropdownItem,
  BTooltip,
  VBTooltip
} from "bootstrap-vue";
import { organizationsHeaders } from "../utils/headers";
import { resolveStatusText, categoryStatus } from "@/utils/status.js";
import { convertDate } from "@/utils/convertDate";
import vSelect from 'vue-select'

export default {
  name: "OrganizationsView",
  components: {
    BCardTitle,
    BTable,
    BCard,
    BButton,
    BRow,
    BPagination,
    BCol,
    BIconTrash,
    BModal,
    BIconPen,
    BFormInput,
    vSelect,
    BIconPlusCircle,
    BContainer,
    BAvatar,
    BImg,
    BIconEye,
    BAvatarGroup,
    BDropdown,
    BDropdownItem,
    BTooltip,
    VBTooltip
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: organizationsHeaders,
      search: "",
      perPage: 10,
      currentPage: 1,
      options: [
        10, 50, 100
      ],
    };
  },
  async created() {
    await this.refetchData()
  },
  computed: {
    organizations() {
      return this.$store.state.organizationConfig.organizations;
    },

    filterOrganization() {
      return this.organizations.filter((organization) => {
        return organization.title.toLowerCase().includes(this.search.toLowerCase());
      })
    },
    rows() {
      return this.filterOrganization.length
    }
  },


  methods: {
    async refetchData() {
      await this.$store.dispatch("getAllOrganizations");
    },
    clickEdit(row) {
      this.$router.push("/organizations/edit/" + row.item.id);
    },
    clickModal(row) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete that line.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deleteOrganization(row.item.id);
          }
        });
    },
    async deleteOrganization(id) {
      await this.$store.dispatch("deleteOrganization", id);
      await this.refetchData();
    },
    resolveStatusText(status) {
      return resolveStatusText(status);
    },
    categoryStatus(status) {
      return categoryStatus(status);
    },
    convertCreatedDate(date) {
      return convertDate(date);
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.clickable-row:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
</style>
